import classNames from '../classNames'
import { IS_DISABLED } from '../constants'
import { BEMblock } from '../helpers'

export default () => {
  const brandSelect = document.querySelector(`.${classNames.searchForm.brands}`)
  const modelsSelect = document.querySelector(`.${classNames.searchForm.models}`)

  if (!brandSelect || !modelsSelect) return

  const modelsSelectEl = modelsSelect.querySelector('select')
  const brandSelectEl = brandSelect.querySelector('select')

  const onChange = ({ currentTarget }) => {
    if (currentTarget.value) {
      BEMblock(modelsSelect, 'input').removeMod(IS_DISABLED)
      modelsSelectEl.removeAttribute('disabled')

      brandSelectEl.removeEventListener('change', onChange)
    }
  }

  brandSelectEl.addEventListener('change', onChange)
}
