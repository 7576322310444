import classes from '../../classNames'

const {
  slider: { hasReachStart, hasReachEnd, showCenterPart },
  plugin: { buttonDisabled },
} = classes.slider

export default function handleBlogSliderChange() {
  if (!window.matchMedia('(min-width: 1200px)').matches) return

  this.blogSliders.forEach(({ navigation, wrap }) => {
    const btns = Object.values(navigation)

    const [reachStart] = btns.filter(
      btn => btn.classList.contains('slider__prev') && btn.classList.contains(buttonDisabled)
    )
    const [reachEnd] = btns.filter(
      btn => btn.classList.contains('slider__next') && btn.classList.contains(buttonDisabled)
    )

    const addStartState = () => {
      wrap.classList.add(hasReachStart)
      wrap.classList.remove(hasReachEnd)
      wrap.classList.remove(showCenterPart)
    }

    const addEndState = () => {
      wrap.classList.add(hasReachEnd)
      wrap.classList.remove(hasReachStart)
      wrap.classList.remove(showCenterPart)
    }

    const addCenterState = () => {
      wrap.classList.remove(hasReachStart)
      wrap.classList.remove(hasReachEnd)
      wrap.classList.add(showCenterPart)
    }

    if (reachStart) {
      addStartState()
    } else if (reachEnd) {
      addEndState()
    } else {
      addCenterState()
    }
  })
}
