import classNames from '../classNames'

export default app => {
  const popups = [...document.querySelectorAll(`.${classNames.popup}`)]
  if (!popups.length) return

  const getGtagValues = name => {
    const priceText = document.querySelector('.order-block--price .price-block__price').innerText

    let value = priceText.match(/\d/g)
    value = value.join('')

    const urlToArr = window.location.href.replace(`#${name}`, '').split('-')
    console.log(urlToArr)
    let id = urlToArr[urlToArr.length - 1].match(/\d/g)
    id = id.join('')

    return {
      value,
      id,
    }
  }

  import(/* webpackChunkName: "popup" */ 'popup-simple/index.min').then(({ default: Popup }) => {
    app.popup = new Popup()
    app.popup.onOpen = () => {
      const getOrderPopup = () => app.popup.popups.filter(({ id }) => id === 'popup-order')[0]
      const getThanksPopup = () => app.popup.popups.filter(({ id }) => id === 'popup-thanks')[0]

      const orderPopup = app.popup.name === '#popup-order' ? getOrderPopup() : null
      const thanksPopup = app.popup.name === '#popup-thanks' ? getThanksPopup() : null

      if (orderPopup) {
        const { id: itemId, value: price } = getGtagValues('popup-order')

        window.gtag('event', 'add_to_cart', {
          send_to: 'AW-457671231',
          value: price,
          items: [
            {
              id: itemId,
              google_business_vertical: 'retail',
            },
          ],
        })
      }

      if (thanksPopup) {
        const { id: itemId, value: price } = getGtagValues('popup-thanks')

        window.gtag('event', 'purchase', {
          send_to: 'AW-457671231',
          value: price,
          items: [
            {
              id: itemId,
              google_business_vertical: 'retail',
            },
          ],
        })
      }
    }

    app.popup.init()
  })
}
