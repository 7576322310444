import classNames from '../classNames'
import { BEMblock } from '../helpers'
import { IS_OPEN, IS_ACTIVE } from '../constants'

export default () => {
  const openDropdown = (wrap, dropdown) => {
    BEMblock(wrap, 'nav__item').addMod(IS_ACTIVE)
    BEMblock(dropdown, 'nav__sublist').addMod(IS_OPEN)
    dropdown.style.maxHeight = `${dropdown.scrollHeight}px`
  }

  const closeDropdown = (wrap, dropdown) => {
    BEMblock(wrap, 'nav__item').removeMod(IS_ACTIVE)
    BEMblock(dropdown, 'nav__sublist').removeMod(IS_OPEN)
    dropdown.style.maxHeight = ''
  }

  const onClick = ({ target }) => {
    if (window.matchMedia('(min-width: 1400px)').matches) return
    const trigger = target.closest(`.${classNames.navDropdown}`)
    if (!trigger) return

    const wrap = trigger.classList.contains('nav__item') ? trigger : trigger.closest('.nav__item')
    const dropdown = wrap.querySelector('.nav__sublist')

    if (BEMblock(wrap, 'nav__item').containsMod(IS_ACTIVE)) {
      closeDropdown(wrap, dropdown)
    } else {
      openDropdown(wrap, dropdown)
    }
  }
  document.addEventListener('click', onClick)
}
