import { debounce } from 'throttle-debounce'
import { isWebkit } from '../helpers'
import classNames from '../classNames'

export default app => {
  if (isWebkit) return
  const containers = [...document.querySelectorAll(`.${classNames.scrollbar}`)]
  if (!containers.length) return

  app.scrollbars = []
  let inited = false
  let SimpleBar

  const init = () => {
    if (inited) return

    containers.forEach(container => {
      const { media } = container.dataset

      if (media && !window.matchMedia(media).matches) return

      const simplebar = new SimpleBar(container)

      app.scrollbars.push({
        container,
        simplebar,
      })

      if (app.scrollbars.length === containers.length) inited = true
    })
  }

  const setScrollbars = () => {
    import(/* webpackChunkName: "simplebar" */ 'simplebar').then(({ default: plugin }) => {
      SimpleBar = plugin
      init()
    })
  }

  setScrollbars()
  const onResize = debounce(200, init)
  window.addEventListener('resize', onResize)
}
