export default ({ target }) => {
  let { children } = target
  children = children.length >= 10 ? [...children].slice(0, 10) : children

  if (children.length > 0) {
    import(/* webpackChunkName: "animejs" */ 'animejs').then(({ default: anime }) => {
      const tl = anime.timeline({ easing: 'easeOutQuad' })

      tl.add({
        targets: target,
        opacity: 1,
        duration: 500,
      }).add(
        {
          targets: children,
          opacity: [0, 1],
          translateX: [30, 0],
          delay: anime.stagger(100),
          duration: 750,
          complete() {
            ;[...children].forEach(el => {
              el.style.opacity = ''
              el.style.transform = ''
            })
            target.removeAttribute('data-animated-el')
          },
        },
        '-=500'
      )
    })
  }
}
