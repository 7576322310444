import classNames from '../../classNames'

export default async app => {
  const button = document.querySelector(`.${classNames.file.btn}`)
  if (!button) return

  const { default: FileImage } = await import(/* webpackChunkName: "file-image" */ './FileImage')

  app.fileImage = new FileImage(button)
  app.fileImage.init()
}
