import classNames from '../classNames'

export default () => {
  const onClick = e => {
    const btn =
      e.target.closest(`.${classNames.scrollTo.top}`) ||
      e.target.closest(`.${classNames.scrollTo.next}`)
    if (!btn) return

    e.preventDefault()
    let top
    let target
    const offset = document.querySelector('.header').offsetHeight

    if (btn.classList.contains(classNames.scrollTo.top)) top = 0
    if (btn.classList.contains(classNames.scrollTo.next)) {
      const parent = btn.closest('section')
      if (!parent) return
      target = parent.nextElementSibling
      top = target.getBoundingClientRect().top - offset
    }

    const options = {
      top,
      behavior: 'smooth',
    }

    if (target) window.scrollBy(options)
    else window.scroll(options)
  }

  document.addEventListener('click', onClick)
}
