import classNames from '../classNames'

export default () => {
  const lgs = [...document.querySelectorAll(`.${classNames.lightgallery.wrap}`)]

  if (!lgs.length) return

  const arrowIcon = `
    <svg viewBox="0 0 48 48" width="1em" height="1em" class="icon icon--arrow">
      <path d="M11.2 24L25.5 6H30L15.8 24 30 42h-4.5L11.2 24z"></path>
    </svg>
  `

  import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js').then(() => {
    import(/* webpackChunkName: "lg-thumbnail" */ 'lg-thumbnail.js').then(() => {
      const addButtonListener = (container, button) => {
        const [firstItem] = container.children
        if (!firstItem) return

        button.addEventListener('click', () => {
          firstItem.click()
        })
      }

      lgs.forEach(lg => {
        const button = lg.parentNode.querySelector(`.${classNames.lightgallery.btn}`)
        if (button) addButtonListener(lg, button)

        // eslint-disable-next-line
        lightGallery(lg, {
          exThumbImage: 'data-exthumbimage',
          nextHtml: arrowIcon,
          prevHtml: arrowIcon,
        })
      })
    })
  })
}
