import classNames from '../classNames'

export default () => {
  const forms = [...document.querySelectorAll(`.${classNames.brandsForm}`)]
  if (!forms.length) return

  const onChange = (form, { currentTarget }) => {
    form.setAttribute('action', currentTarget.value)
  }

  forms.forEach(form => {
    const selects = [...form.querySelectorAll('select')]

    selects.forEach(select => {
      select.addEventListener('change', onChange.bind(null, form))
    })
  })
}
