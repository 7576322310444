export const {
  STATE,
  IS_ACTIVE,
  IS_OPEN,
  IS_READY,
  IS_LOADED,
  IS_ANIMATE,
  IS_HIDDEN,
  IS_STICKY,
  IS_INITED,
  NO_SCROLL,
  NO_TOUCH,
  HAS_ERROR,
  IS_SUCCESS,
  IS_DISABLED,
  ONLINE,
  OFFLINE,
} = {
  STATE: 'data-state',
  IS_ACTIVE: 'active',
  IS_OPEN: 'open',
  IS_READY: 'is-ready',
  IS_LOADED: 'loaded',
  IS_ANIMATE: 'animate',
  IS_HIDDEN: 'hidden',
  IS_STICKY: 'sticky',
  IS_INITED: 'initialized',
  NO_SCROLL: 'no-scroll',
  NO_TOUCH: 'no-touch',
  HAS_ERROR: 'error',
  IS_SUCCESS: 'success',
  IS_DISABLED: 'disabled',
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
}
