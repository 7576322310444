import classNames from '../classNames'
// import { IS_ACTIVE } from '../constants'

export default app => {
  const containers = [...document.querySelectorAll(`.${classNames.tabs.wrap}`)]
  if (!containers.length) return

  import(/* webpackChunkName: "tabs" */ '../lib/tabs').then(({ default: Tabs }) => {
    app.tabs = new Tabs({
      classNames: {
        btn: 'contacts-map__tab',
        item: 'contacts-map__map',
      },
    })

    app.tabs.init()
  })
}
