import classNames from '../classNames'
import { BEMblock } from '../helpers'
import { IS_SUCCESS } from '../constants'

export default () => {
  const hideMessage = e => {
    const hideMessageBtn = e.target.closest(`.${classNames.form.hideMessage}`)

    if (!hideMessageBtn) return
    const wrap = hideMessageBtn.closest('.form')
    if (wrap) BEMblock(wrap, 'form').removeMod(IS_SUCCESS)
  }

  document.addEventListener('click', hideMessage)
  // const { forms } = app.dom
  // if (!forms.length) return

  // app.forms = []

  // const checkLength = input => {
  //   const [imask] = app.masks.length > 0 ? app.masks.filter(mask => mask.el.input === input) : [{}]

  //   return imask.masked ? imask.masked.isComplete : false
  // }

  // const addInputError = (input, errors) => {
  //   const field = input.closest('.input')
  //   if (!field) return
  //   const btn = input.closest('form').querySelector('button')

  //   // const message = input.dataset.errorMessage || 'Заполните поле целиком'
  //   // const label = field.querySelector('.input__label--error')
  //   const wrap = input.closest('.form')

  //   if (wrap) BEMblock(wrap, 'form').addMod(HAS_ERROR)
  //   // if (label) label.innerHTML = message
  //   BEMblock(field, 'input').addMod(HAS_ERROR)

  //   if (Object.keys(errors).length > 0 && btn) {
  //     btn.setAttribute('disabled', '')
  //     BEMblock(btn, 'btn').addMod(IS_DISABLED)
  //   }
  // }

  // const removeInputError = (input, errors) => {
  //   const field = input.closest('.input')
  //   if (!field) return
  //   const wrap = input.closest('.form')
  //   const btn = input.closest('form').querySelector('button')

  //   BEMblock(field, 'input').removeMod(HAS_ERROR)

  //   if (!Object.keys(errors).length) {
  //     if (wrap) BEMblock(wrap, 'form').removeMod(HAS_ERROR)
  //     if (btn) {
  //       btn.removeAttribute('disabled')
  //       BEMblock(btn, 'btn').removeMod(IS_DISABLED)
  //     }
  //   }
  // }

  // const hideMessage = e => {
  //   const hideMessageBtn = e.target.closest(`.${classNames.form.hideMessage}`)

  //   if (!hideMessageBtn) return
  //   const wrap = hideMessageBtn.closest('.form')
  //   if (wrap) BEMblock(wrap, 'form').removeMod(IS_SUCCESS)
  // }

  // import(/* webpackChunkName: "validation" */ '../lib/validation').then(
  //   ({ default: Validator }) => {
  //     forms.forEach(validatedForm => {
  //       const validator = new Validator(
  //         validatedForm,
  //         {
  //           phone: checkLength,
  //           email: 'email',
  //         },
  //         {
  //           inputEvents: ['input', isEdge || isIE ? 'blur' : 'change'],
  //         }
  //       )

  //       validator.addSubmitError = (form, inputs, errors) => {
  //         if (!Object.keys(errors).length) return

  //         Object.values(errors).forEach(({ input }) => addInputError(input))
  //       }
  //       validator.addError = (input, errors) => addInputError(input, errors)
  //       validator.removeError = (input, errors) => removeInputError(input, errors)

  //       validator.init()

  //       app.forms.push({
  //         form: validatedForm,
  //         validator,
  //       })
  //     })

  //     document.addEventListener('click', hideMessage)
  //   }
  // )
}
