import classes from '../../classNames'
import { IS_INITED } from '../../constants'
import { BEMblock } from '../../helpers'

const classNames = classes.slider

export default class MySlider {
  constructor(container, getOptions, app) {
    this.app = app
    this.container = container
    this.name = container.dataset.slider
    this.wrap = container.closest(`.${classNames.slider.wrap}`)
    this.sliderEl = container.querySelector(`.${classNames.plugin.wrapper}`)
    this.navigation = {
      prevEl: this.wrap.querySelector(`.${classNames.slider.prev}`),
      nextEl: this.wrap.querySelector(`.${classNames.slider.next}`),
    }
    this.pagination = this.wrap.querySelector(`.${classNames.slider.pagination}`)

    this.nameMod = undefined // if need to reinit slider with different options

    this.options = getOptions({
      navigation: this.navigation,
      pagination: this.pagination,
      onInit: this.app.lazyLoader.observe.bind(this.app.lazyLoader),
    })[this.nameMod || this.name]

    this.inited = false
  }

  get slides() {
    return [...this.container.querySelectorAll(`.${classNames.slider.slide}`)]
  }

  _initPlugin() {
    const init = Swiper => {
      this.swiper = new Swiper(this.container, this.options)
      BEMblock(this.wrap, 'slider__wrap').addMod(IS_INITED)
      this.inited = true
    }

    import(/* webpackChunkName: "swiper" */ 'swiper/js/swiper').then(({ default: Swiper }) =>
      init(Swiper)
    )
  }

  destroy(deleteInstance, cleanStyles) {
    if (!this.swiper.destroy) return
    this.swiper.destroy(deleteInstance, cleanStyles)
    this.sliderEl.style.transform = ''
    BEMblock(this.wrap, 'slider__wrap').removeMod(IS_INITED)

    this.inited = false
  }

  init() {
    this._initPlugin()
  }
}
