import classNames from '../classNames'
import { BEMblock } from '../helpers'
import { IS_OPEN } from '../constants'

export default () => {
  const dropdowns = [...document.querySelectorAll(`.${classNames.dropdown}`)]
  if (!dropdowns.length) return

  let HAS_OPEN_DROPDOWN = false
  const DROPDOWN_CLASS = 'contacts-map-dropdown'

  const openDropdown = dropdown => {
    BEMblock(dropdown, DROPDOWN_CLASS).addMod(IS_OPEN)
    HAS_OPEN_DROPDOWN = true
  }

  const closeDropdown = dropdown => {
    if (dropdown) {
      BEMblock(dropdown, DROPDOWN_CLASS).removeMod(IS_OPEN)
    } else {
      dropdowns.forEach(d => BEMblock(d, DROPDOWN_CLASS).removeMod(IS_OPEN))
    }
    HAS_OPEN_DROPDOWN = false
  }

  const onClick = e => {
    const dropdown = e.target.closest(`.${classNames.dropdown}`)

    if (!dropdown && HAS_OPEN_DROPDOWN) closeDropdown(dropdown)
    if (dropdown) {
      const opener = e.target.closest('[class*="__opener"]')
      const option = e.target.closest('[class*="__option"]')

      if ((opener && HAS_OPEN_DROPDOWN) || (option && HAS_OPEN_DROPDOWN)) closeDropdown(dropdown)
      else openDropdown(dropdown)
    }
  }

  document.addEventListener('click', onClick)
}
