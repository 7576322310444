import { throttle } from 'throttle-debounce'
import classNames from '../classNames'
import { BEMblock } from '../helpers'
import { IS_STICKY, IS_HIDDEN } from '../constants'

export default () => {
  const panel = document.querySelector(`.${classNames.filtersPanel}`)
  if (!panel) return

  let lastScrollTop = 0
  const cards = panel.nextElementSibling

  const handleScroll = () => {
    const OFFSET = 64
    const ANIMATION_DURATION = 500
    const BLOCK_CLASS_NAME = 'section__filters'

    const panelTop = cards.getBoundingClientRect().top > OFFSET + panel.offsetHeight
    const cardsTop = cards.getBoundingClientRect().top > OFFSET
    const isPanelHide = panel.getBoundingClientRect().top + panel.offsetHeight <= OFFSET

    const st = window.pageYOffset

    const hidePanel = () => {
      BEMblock(panel, BLOCK_CLASS_NAME).addMod(IS_HIDDEN)

      setTimeout(() => {
        BEMblock(panel, BLOCK_CLASS_NAME).removeMod(IS_HIDDEN)
        BEMblock(panel, BLOCK_CLASS_NAME).removeMod(IS_STICKY)
      }, ANIMATION_DURATION)
    }

    if (st > lastScrollTop) {
      if (cardsTop) {
        BEMblock(panel, BLOCK_CLASS_NAME).removeMod(IS_STICKY)
      } else if (BEMblock(panel, BLOCK_CLASS_NAME).containsMod(IS_STICKY)) {
        hidePanel()
      }
    } else if (panelTop) {
      BEMblock(panel, BLOCK_CLASS_NAME).removeMod(IS_STICKY)
    } else if (isPanelHide) {
      BEMblock(panel, BLOCK_CLASS_NAME).addMod(IS_STICKY)
    }

    lastScrollTop = st <= 0 ? 0 : st
  }

  const onScroll = throttle(200, handleScroll)
  window.addEventListener('scroll', onScroll)
}
