export default () => {
  const form = document.getElementById('products_search')
  if (!form) return

  const typeCheckboxes = [...form.querySelectorAll('.filter_checkbox[name="ProductsTypes[]"]')]

  const onChange = ({ target }) => {
    const [typeCheckbox] = typeCheckboxes.filter(input => input === target)
    if (!typeCheckbox) return

    const elseCheckboxes = typeCheckboxes.filter(input => input !== typeCheckbox)

    elseCheckboxes.forEach(input => {
      input.checked = false
    })
  }

  form.addEventListener('change', onChange)
}
