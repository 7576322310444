import { BEMblock } from '../../helpers'
import { IS_ANIMATE } from '../../constants'

export default ({ target }) => {
  const title = target.querySelector('.section__title')
  const article = target.querySelector('.article__inner')
  const slider = target.querySelector('.slider__wrap')

  if (title || article || slider) {
    import(/* webpackChunkName: "animejs" */ 'animejs').then(({ default: anime }) => {
      if (title) BEMblock(title, 'section__title').addMod(IS_ANIMATE)

      if (article) {
        article.style.setProperty('--animation-delay', '1s')
        BEMblock(article, 'article__inner').addMod(IS_ANIMATE)
      }

      if (slider) {
        anime({
          targets: slider,
          duration: 1000,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2300,
        })
      }
    })
  }
}
