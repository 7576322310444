import regeneratorRuntime from 'regenerator-runtime'
import './public-path'
import loadPolyfills from './polyfills/loadPolyfills'
import classNames from './classNames'
import setHTMLClassNames from './methods/setHTMLClassNames'
import setScrollbar from './methods/setScrollbar'
import setAccordion from './methods/setAccordion'
import setTextareaHeight from './methods/setTextareaHeight'
import scrollTo from './methods/scrollTo'
import setTabs from './methods/setTabs'
import setDropdown from './methods/setDropdown'
import handleForm from './methods/handleForm'
import setInputMask from './methods/setInputMask'
import setAnimations from './methods/animations'
import setNavDropdown from './methods/setNavDropdown'
import setGallery from './methods/setGallery'
import toggleFilterBtn from './methods/toggleFilterBtn'
import setSkickyFiltersPanel from './methods/setSkickyFiltersPanel'
import setPopups from './methods/setPopups'
import handleBrandsForm from './methods/handleBrandsForm'
import setYotubePoster from './methods/setYoutubePoster'
import toggleSearchSelect from './methods/toggleSearchSelect'

import Menu from './components/Menu/Menu'
import Slider from './components/Slider/Slider'
import LazyLoader from './components/LazyLoader/LazyLoader'
import setCardsToggler from './components/CardsToggler/setCardsToggler'
import setRangeSliders from './components/RangeSlider/setRangeSliders'
import setFileImage from './components/FileImage/setFileImage'

import { NO_SCROLL } from './constants'
import { isModernBrowser, isSafari, setVhProperty } from './helpers'

class App {
  constructor() {
    this.methods = {}
    this.classNames = classNames
    this.dom = {
      body: document.body,
      forms: [...document.querySelectorAll(`.${classNames.form.form}`)],
    }
    this.state = {
      hasMenuOpen: false,
    }

    this.lazyLoader = new LazyLoader({
      // TODO: Uncomment to enable dynamic replacing all lazyloaded images to .webp format.
      checkWebP: true,
    })

    this.menu = new Menu(this, {
      classNames: {
        btn: 'burger',
        menu: 'header__body',
      },
    })
    this.slider = new Slider(`.${classNames.slider.container}`, this)
    this.rangeSlider = null
  }

  updateState(state) {
    this.state = {
      ...this.state,
      ...state,
    }
  }

  initMethods() {
    this.methods = {
      setHTMLClassNames,
      setVhProperty,
      setScrollbar,
      setAccordion,
      setTextareaHeight,
      scrollTo,
      setTabs,
      setDropdown,
      handleForm,
      setInputMask,
      setAnimations,
      setNavDropdown,
      setGallery,
      toggleFilterBtn,
      setSkickyFiltersPanel,
      setPopups,
      setCardsToggler,
      handleBrandsForm,
      setRangeSliders,
      setYotubePoster,
      setFileImage,
      toggleSearchSelect,
    }

    Object.values(this.methods).forEach(fn => fn(this))
  }

  init() {
    this.initMethods()

    this.lazyLoader.init()
    this.menu.init()
    this.slider.init()
  }

  preventScroll() {
    this.dom.body.classList.add(NO_SCROLL)
  }

  allowScroll() {
    this.dom.body.classList.remove(NO_SCROLL)
  }

  toggleScroll(condition) {
    // eslint-disable-next-line
    condition ? this.preventScroll() : this.allowScroll()
  }
}

const init = () => {
  const app = new App()
  app.init()
  window.app = app
}

if (isSafari) {
  document.addEventListener('DOMContentLoaded', loadPolyfills.bind(null, init))
} else if (isModernBrowser) {
  document.addEventListener('DOMContentLoaded', init)
} else {
  document.addEventListener('DOMContentLoaded', loadPolyfills.bind(null, init))
}
