import classes from '../../classNames'

const {
  slider: { hasReachStart, hasReachEnd, showCenterPart },
} = classes.slider

export default function handleBlogSliderWrapClasses() {
  if (!window.matchMedia('(min-width: 1200px)').matches) {
    this.blogSliders.forEach(({ wrap }) => {
      wrap.classList.remove(hasReachStart)
      wrap.classList.remove(hasReachEnd)
      wrap.classList.remove(showCenterPart)
    })
  }
}
