import classNames from '../classNames'
import { IS_ACTIVE } from '../constants'

export default app => {
  const containers = [...document.querySelectorAll(`.${classNames.accordion.wrap}`)]
  if (!containers.length) return

  import(/* webpackChunkName: "accordion" */ '../lib/accordion').then(({ default: Accordion }) => {
    app.accordion = new Accordion({
      classNames: {
        btn: 'question__title',
        item: 'question__content',
      },
    })

    app.accordion.onToggle = () => {
      if (app.accordion.BEMbtn.containsMod(IS_ACTIVE)) {
        app.accordion.item.style.maxHeight = `${app.accordion.item.scrollHeight}px`
      } else {
        app.accordion.item.style.maxHeight = ''
      }
    }

    app.accordion.init()
  })
}
