export default {
  lazy: 'js-lazy',
  scrollbar: 'js-scrollbar',
  textarea: 'js-textarea',
  dropdown: 'js-dropdown',
  navDropdown: 'js-nav-dropdown',
  mask: 'js-input-mask',
  brandsForm: 'js-search-form',
  youtubeVideo: 'js-youtube-video',
  searchForm: {
    brands: 'js-search-brand',
    models: 'js-search-model',
  },
  file: {
    btn: 'js-file-btn',
  },
  rangeSlider: {
    wrap: 'js-range-slider',
    // slider: 'js-range-slider',
    // input: 'js-range-slider-input',
    // value: 'js-range-slider-value',
  },
  cards: {
    wrap: 'js-cards',
    quantityTitle: 'js-cards-all',
  },
  lightgallery: {
    wrap: 'js-lightgallery',
    btn: 'js-lightgallery-btn',
  },
  filtersPanel: 'js-section-filters',
  popup: 'js-popup',
  animate: {
    el: 'js-animated-el',
    section: 'js-animated-section',
  },
  truncate: {
    wrap: 'js-truncate-article',
    btn: 'js-truncate-btn',
  },
  form: {
    form: 'js-valid-form',
    hideMessage: 'js-form-hide-message',
  },
  menu: {
    burger: 'js-burger',
    menu: 'js-menu',
  },
  slider: {
    container: 'js-slider',
    slider: {
      wrap: 'slider__wrap',
      prev: 'js-slider-prev',
      next: 'js-slider-next',
      pagination: 'js-slider-pagination',
      slide: 'swiper-slide',
      hasReachStart: 'has-reached-start',
      hasReachEnd: 'has-reached-end',
      showCenterPart: 'show-center-part',
    },
    plugin: {
      container: 'swiper-container',
      wrapper: 'swiper-wrapper',
      initialized: 'swiper-container-initialized',
      activeSlide: 'swiper-slide-active',
      buttonDisabled: 'swiper-button-disabled',
    },
  },
  accordion: {
    wrap: 'js-accordion',
    item: 'js-accordion-item',
    btn: 'js-accordion-btn',
  },
  scrollTo: {
    top: 'js-scroll-to-top',
    next: 'js-scroll-to-next',
  },
  tabs: {
    wrap: 'js-tabs',
    item: 'js-tabs-item',
    tab: 'js-tabs-btn',
  },
}
