import { HAS_ERROR } from '../../constants'
import { canUseWebP } from '../../helpers'

export default function handleLoadingError(el) {
  // OPTIONAL CODE: Fallback load .jpg or .png if .webp file was not loaded
  const { backgroundImage: bg } = window.getComputedStyle(el)
  const src = el.getAttribute('src')

  const addError = elem => {
    if (elem.classList.contains(`lazy--${HAS_ERROR}`)) return
    elem.classList.add(`lazy--${HAS_ERROR}`)

    if (this.options.checkWebP && canUseWebP) {
      const [currentInfo] = this.WEBP_INFO.filter(img => img.el === elem)
      const {
        src: originalSrc,
        bgImage: originalBgImage,
        bgImages: originalBgImages,
        picture,
      } = currentInfo

      if (originalSrc) elem.src = originalSrc
      if (originalBgImage) elem.style.backgroundImage = `url('${originalBgImage}')`
      if (originalBgImages) {
        elem.setAttribute('data-background-images', originalBgImages)
        this.loadCurrentBgImage(JSON.parse(elem.dataset.backgroundImages), elem)
      }

      if (this.isPicture(elem)) {
        const sources = this.getSources(elem)
        const img = this.getImg(elem)

        const { img: pictureImg, source: pictureSource } = picture

        if (img.src) img.src = pictureImg.src
        if (img.srcset) img.srcset = pictureImg.srcset
        sources.forEach(source => {
          if (source.srcset) source.setAttribute('srcset', pictureSource.srcset)
        })
      }
    }

    if (this.options.onError) this.options.onError(elem)
  }

  if (this.options.checkWebP && canUseWebP && bg !== 'none') {
    const url = bg.slice(5, -2)
    const img = new Image()
    img.src = url

    img.onerror = addError.bind(this, el)
  }

  if (src) el.onerror = addError.bind(this, el)

  if (this.isPicture(el)) {
    this.getImg(el).onerror = addError.bind(this, el)
  }
}
