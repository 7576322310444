import { debounce } from 'throttle-debounce'
import lozad from 'lozad'
import classNames from '../../classNames'
import { IS_LOADED } from '../../constants'
import loadWebPImage from './loadWebPImage'
import handleLoadingError from './handleErrors'

const { lazy: JS_LAZY } = classNames

export default class LazyLoader {
  constructor(
    options = {
      checkWebP: false,
    }
  ) {
    this.options = options
    this.imgs = [...document.querySelectorAll(`.${JS_LAZY}`)]
    this.WEBP_INFO = []

    this.loadWebPImage = loadWebPImage.bind(this)
    this.handleLoadingError = handleLoadingError.bind(this)
  }

  get dynamicBgImages() {
    return this.imgs.filter(img => img.hasAttribute('data-background-images'))
  }

  getImg(el) {
    return el.querySelector('img')
  }

  getSources(el) {
    return [...el.querySelectorAll('source')]
  }

  isPicture(el) {
    return el.tagName.toLowerCase() === 'picture'
  }

  loadCurrentBgImage(srcset, el) {
    const [currentImage] = srcset.filter(({ media }) => window.matchMedia(media).matches)
    el.style.backgroundImage = `url('${currentImage.url}')`
  }

  loadPictureElement(el) {
    const sources = this.getSources(el)
    const img = this.getImg(el)

    if (img.dataset.src) img.src = img.dataset.src
    sources.forEach(source => {
      if (source.dataset.srcset) source.setAttribute('srcset', source.dataset.srcset)
    })
  }

  setImages() {
    this.imgs.forEach(img => {
      img.classList.add('lazy')
    })
  }

  loadImage(el) {
    if (this.options.checkWebP && el.dataset.checkWebp !== 'false') this.loadWebPImage(el)

    const { src, srcset, poster, backgroundImage: bgImage, backgroundImages: bgImages } = el.dataset

    if (src) el.src = src
    if (srcset) el.srcset = srcset
    if (bgImage) el.style.backgroundImage = `url('${bgImage}')`
    if (bgImages) this.loadCurrentBgImage(JSON.parse(bgImages), el)
    if (poster) el.setAttribute('poster', poster)

    if (this.isPicture(el)) this.loadPictureElement(el)

    this.handleLoadingError(el)
  }

  onImageLoad(el) {
    if (el.hasAttribute('data-src')) el.removeAttribute('data-src')
    if (el.hasAttribute('data-srcset')) el.removeAttribute('data-srcset')
    if (el.hasAttribute('data-background-image')) el.removeAttribute('data-background-image')
    if (el.hasAttribute('data-poster')) el.removeAttribute('data-poster')
    if (this.isPicture(el)) {
      this.getImg(el).removeAttribute('data-src')
      this.getSources(el).forEach(source => source.removeAttribute('data-srcset'))
    }
    el.classList.add(`lazy--${IS_LOADED}`)
  }

  handleResize() {
    if (!this.dynamicBgImages.length) return
    this.dynamicBgImages.forEach(el => {
      if (el.dataset.loaded !== 'true') return
      const { backgroundImages: bgImages } = el.dataset

      if (bgImages) this.loadCurrentBgImage(JSON.parse(bgImages), el)
    })
  }

  addListeners() {
    this.onResize = debounce(200, this.handleResize.bind(this))
    window.addEventListener('resize', this.onResize)
  }

  observe() {
    this.observer = lozad(`.${JS_LAZY}`, {
      load: this.loadImage.bind(this),
      loaded: this.onImageLoad.bind(this),
    })
    this.observer.observe()
  }

  init() {
    if (!this.imgs.length) return
    this.setImages()
    this.observe()
    this.addListeners()
  }
}
