import { canUseWebP } from '../../helpers'

export default function loadWebPImage(el) {
  if (!canUseWebP) return
  const { src, poster, backgroundImage: bgImage, backgroundImages: bgImages } = el.dataset
  let picture = { img: {}, srcset: {} }

  const getPath = path => {
    const extensions = ['.jpg', '.JPG', '.png', '.PNG']
    extensions.forEach(ext => {
      if (path.indexOf(ext) > 0) path = path.replace(ext, '.webp')
    })
    return path
  }

  if (src) el.setAttribute('data-src', getPath(src))
  if (poster) el.setAttribute('data-poster', getPath(poster))
  if (bgImage) el.setAttribute('data-background-image', getPath(bgImage))
  if (bgImages) {
    let images = JSON.parse(bgImages)
    images = images.map(image => {
      image.url = getPath(image.url)
      return image
    })

    el.setAttribute('data-background-images', JSON.stringify(images))
  }

  if (this.isPicture(el)) {
    const sources = this.getSources(el)
    const img = this.getImg(el)

    if (img.dataset.src) {
      picture = {
        ...picture,
        img: { ...picture.img, src: img.dataset.src, srcset: img.dataset.srcset },
      }

      img.setAttribute('data-src', getPath(img.dataset.src))
    }
    sources.forEach(source => {
      picture = {
        ...picture,
        source: {
          srcset: source.dataset.srcset,
        },
      }

      if (source.dataset.srcset) source.setAttribute('data-srcset', getPath(source.dataset.srcset))
    })
  }

  this.WEBP_INFO.push({
    el,
    src,
    poster,
    bgImage,
    bgImages,
    picture,
  })
}
