import classNames from '../classNames'

const setYotubePoster = () => {
  const players = [...document.querySelectorAll(`.${classNames.youtubeVideo}`)]
  if (!players) return

  const Iframe = ({ src, height }) => `
    <iframe
      class="section__video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen=""
      src="${src}"
      height="${height}"
    ></iframe>
  `

  const onClick = ({ currentTarget }) => {
    let { src } = currentTarget.dataset
    src += '?autoplay=1&amp;rel=0&amp;showinfo=0'
    const height = currentTarget.offsetHeight

    const template = document.createElement('template')
    template.innerHTML = Iframe({ src, height })
    const iframe = template.content.cloneNode(true)

    currentTarget.parentNode.insertBefore(iframe, currentTarget)
    currentTarget.removeEventListener('click', onClick)
    currentTarget.parentNode.removeChild(currentTarget)
  }

  players.forEach(player => {
    player.addEventListener('click', onClick)
  })
}

export default setYotubePoster
