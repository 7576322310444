import { debounce } from 'throttle-debounce'
import MySlider from './MySlider'
import handleBlogSliderChange from './handleBlogSliderChange'
import handleBlogSliderWrapClasses from './handleBlogSliderWrapClasses'
import handleGallerySliderInit from './handleGallerySliderInit'
import classes from '../../classNames'

const classNames = classes.slider

export default class Slider {
  constructor(slider, app) {
    this.sliderClass = slider
    this.sliders = []
    this.app = app

    this.handleBlogSliderChange = handleBlogSliderChange.bind(this)
    this.handleBlogSliderWrapClasses = handleBlogSliderWrapClasses.bind(this)
    this.handleGallerySliderInit = handleGallerySliderInit.bind(this)
  }

  get blogSliders() {
    return this.sliders.filter(s => s.name === 'blog')
  }

  _getOptions() {
    this.getOptions = ({ navigation, onInit, pagination }) => ({
      hero: {
        navigation,
        slidesPerView: 1,
        spaceBetween: 10,
        shortSwipes: false,
        longSwipesRatio: 0.05,
        longSwipesMs: 100,
        grabCursor: true,
        autoplay: {
          delay: 6000,
        },
        loop: true,
        on: {
          init: onInit,
        },
        pagination: {
          el: pagination,
          dynamicBullets: true,
          clickable: true,
        },
      },
      services: {
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true,
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        shortSwipes: false,
        longSwipesRatio: 0.05,
        longSwipesMs: 100,
        grabCursor: true,
        on: {
          init: onInit,
        },
        breakpoints: {
          576: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: false,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 15,
            centeredSlides: false,
          },
        },
      },
      'services-sm': {
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true,
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        shortSwipes: false,
        longSwipesRatio: 0.05,
        longSwipesMs: 100,
        grabCursor: true,
        on: {
          init: onInit,
        },
        breakpoints: {
          576: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: false,
          },
        },
      },
      model: {
        navigation,
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true,
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        shortSwipes: false,
        longSwipesRatio: 0.05,
        longSwipesMs: 100,
        grabCursor: true,
        on: {
          init: onInit,
        },
        breakpoints: {
          768: {
            slidesPerView: 1,
            centeredSlides: false,
          },
        },
      },
      blog: {
        navigation,
        slidesPerView: 'auto',
        centeredSlides: true,
        shortSwipes: false,
        longSwipesRatio: 0.05,
        longSwipesMs: 100,
        grabCursor: true,
        pagination: {
          el: pagination,
          dynamicBullets: true,
          clickable: true,
        },
        on: {
          init: onInit,
          transitionEnd: this.handleBlogSliderChange.bind(this),
        },
        breakpoints: {
          767: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: false,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 15,
            centeredSlides: false,
          },
          1200: {
            slidesPerView: 'auto',
            spaceBetween: 15,
            centeredSlides: false,
          },
        },
      },
      'fin-services': {
        spaceBetween: 15,
        slidesPerView: 'auto',
        centeredSlides: true,
        shortSwipes: false,
        longSwipesRatio: 0.05,
        longSwipesMs: 100,
        grabCursor: true,
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true,
        },
        on: {
          init: onInit,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            centeredSlides: false,
          },
        },
      },
      gallery: {
        slidesPerView: 1,
        pagination: {
          el: pagination,
          dynamicBullets: true,
          clickable: true,
        },
        on: {
          init: onInit,
        },
      },
      'section-gallery': {
        navigation,
        slidesPerView: 1,
        pagination: {
          el: pagination,
          dynamicBullets: true,
          clickable: true,
        },
        on: {
          init: onInit,
        },
      },
    })
  }

  _initSliders() {
    this.containers.forEach(container => {
      if (container.classList.contains(classNames.plugin.initialized)) return

      const slider = new MySlider(container, this.getOptions, this.app)

      if (slider.name === 'gallery') {
        if (!window.matchMedia('(min-width: 576px )').matches) {
          slider.init()
        }
      } else if (slider.name === 'hero') {
        setTimeout(() => slider.init(), 3000)
      } else {
        slider.init()
      }

      this.sliders = [...this.sliders, slider]
    })
  }

  _addListeners() {
    this.onResize = debounce(200, this.handleResize.bind(this))

    window.addEventListener('resize', this.onResize)
  }

  handleResize() {
    this.handleGallerySliderInit()
    this.handleBlogSliderWrapClasses()
  }

  init() {
    this.containers = [...document.querySelectorAll(this.sliderClass)]
    if (!this.containers.length) return

    this._getOptions()
    this._initSliders()
    this._addListeners()
  }
}
