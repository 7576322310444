import classNames from '../classNames'

export default function setInputMask(app) {
  const maskedInputs = [...document.querySelectorAll(`.${classNames.mask}`)]

  if (!maskedInputs.length) return

  function getOptions({ mask, IMask, moment }) {
    return {
      phone: {
        mask,
        lazy: true,
        placeholderChar: 'X',
      },
      number: {
        mask: Number,
      },
      // year: {
      //   mask: Date,
      //   pattern: 'YYYY',
      //   placeholderChar: 'X',
      //   lazy: false,
      //   overwrite: true,

      //   format(date) {
      //     return moment(date).format('YYYY')
      //   },
      //   parse(str) {
      //     return moment(str, 'YYYY')
      //   },
      //   blocks: {
      //     YYYY: {
      //       mask: IMask.MaskedRange,
      //       from: 1970,
      //       to: 2030,
      //     },
      //   },
      // },
    }
  }

  const init = IMask => {
    app.masks = []
    maskedInputs.forEach(async input => {
      const type = input.dataset.maskType || 'phone'
      const { mask } = input.dataset || '+{38}(000)000-00-00'

      let moment

      // if (type === 'year') {
      //   const { default: m } = await import(/* webpackChunkName: "moment" */ 'moment')
      //   moment = m
      // }

      const imask = IMask(input, getOptions({ mask, IMask, moment })[type])
      app.masks.push(imask)

      let inputValue = ''

      if (type !== 'number') {
        input.addEventListener('input', () => {
          inputValue = imask.masked.rawInputValue
        })
        input.addEventListener('focus', () => {
          imask.updateOptions({ lazy: false })
        })
        input.addEventListener('blur', () => {
          imask.updateOptions({ lazy: true })
          imask.value = inputValue
        })
      }
    })
  }

  if (!('globalThis' in window)) {
    import(/* webpackChunkName: "global-this-polyfill" */ 'core-js/features/global-this').then(
      () => {
        import(/* webpackChunkName: "imask" */ 'imask').then(({ default: IMask }) => init(IMask))
      }
    )
  } else {
    import(/* webpackChunkName: "imask" */ 'imask').then(({ default: IMask }) => init(IMask))
  }
}
