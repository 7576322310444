import animateInView from './animateInView'
import animateTitle from './titles'
import animateArticle from './articles'
import animateStagger from './stagger'
import animateSpecialOffer from './specialOffer'
import animateContacts from './contacts'

import classNames from '../../classNames'

export default () => {
  const animatedEls = [...document.querySelectorAll(`.${classNames.animate.el}`)]
  const animatedSections = [...document.querySelectorAll(`.${classNames.animate.section}`)]

  const getElements = title => animatedEls.filter(el => el.dataset.animatedEl === title)
  const getSection = title => animatedSections.filter(el => el.dataset.animatedSection === title)[0]

  const elements = {
    titles: {
      els: getElements('title'),
      onEnter: animateTitle,
    },
    articles: {
      els: getElements('article'),
      onEnter: animateArticle,
    },
    stagger: {
      els: getElements('stagger'),
      onEnter: animateStagger,
    },
  }

  const sections = {
    specialOffer: {
      el: getSection('special-offer'),
      onEnter: animateSpecialOffer,
    },
    contacts: {
      el: getSection('contacts'),
      onEnter: animateContacts,
    },
  }

  Object.values(elements).forEach(({ els, onEnter }) => {
    if (els.length > 0) els.forEach(el => animateInView({ el, onEnter }))
  })

  Object.values(sections).forEach(({ el, onEnter }) => {
    if (el) {
      animateInView({
        el,
        onEnter,
        options: { treshold: 0.3 },
      })
    }
  })
}
