import { BEMblock } from '../../helpers'
import { IS_ANIMATE } from '../../constants'

export default ({ target }) => {
  const title = target.querySelector('.section__title')
  const contacts = target.querySelector('.contacts')

  if (title) {
    BEMblock(title, 'section__title').addMod(IS_ANIMATE)
  }

  if (contacts) {
    const formTitleBlock = contacts.querySelector('.contacts-form__title-block')
    const formBlock = contacts.querySelector('.contacts-form__form')
    const map = contacts.querySelector('.contacts-map')
    const social = contacts.querySelector('.contacts__social-wrap')

    BEMblock(contacts, 'contacts').removeMod(IS_ANIMATE)

    import(/* webpackChunkName: "animejs" */ 'animejs').then(({ default: anime }) => {
      const tl = anime.timeline({ easing: 'easeOutQuad' })

      tl.add({
        targets: formTitleBlock,
        opacity: [0, 1],
        translateX: [-30, 0],
        duration: 750,
      })
        .add(
          {
            targets: formBlock,
            opacity: [0, 1],
            translateX: [30, 0],
            duration: 750,
          },
          '-=750'
        )
        .add({
          targets: map,
          opacity: [0, 1],
          duration: 750,
        })
        .add({
          targets: social,
          opacity: [0, 1],
          duration: 750,
        })
    })
  }
}
